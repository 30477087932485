import { URL_COMPANY } from '@/constants/global';
import { EstimationStage, isNotEmpty, PropertyEnum } from '@proprioo/hokkaido';

import { ListingInformationProps } from './information/tableInformation/TableInformation';
import {
  ListingAttributes,
  ListingFeesRecurrenceType,
  ListingFrontType,
  ListingHeatingLocation,
  ListingHeatingMode,
  ListingHeatingType,
  ListingLivingRoomViewType,
  ListingParkingType,
  ListingQuality,
  ListingWindowType
} from './listing/Listing.interfaces';

export const generateLocalLink = (url: string) => url.replace(URL_COMPANY, '');

export const isOnHold = (stage: EstimationStage) =>
  stage === EstimationStage.ON_HOLD;

export const formatCriterias = (listing: ListingAttributes) => {
  const {
    hasPool,
    quality,
    elevator,
    floor,
    cellar,
    annex,
    balconySurface,
    gardenSurface,
    parking,
    box
  } = listing;

  return {
    hasPool,
    groundFloor: floor === 0,
    hasCellarOrAnnex: Boolean(annex || cellar),
    hasLift: Boolean(elevator),
    hasParking: Boolean(parking || box),
    hasOutdoorSpace: Boolean(balconySurface || gardenSurface),
    withoutRenovationWork: Boolean(
      quality === ListingQuality.NEW || quality === ListingQuality.CORRECT
    )
  };
};

export const formatFacade = (facade?: ListingFrontType): string => {
  switch (facade) {
    case ListingFrontType.CUT_STONE:
      return 'stonebrick';
    case ListingFrontType.BRICK:
      return 'brick';
    case ListingFrontType.CONCRETE:
      return 'concrete';
    case ListingFrontType.COAT:
      return 'coat';
    case ListingFrontType.CLASSIC:
      return 'classic';
    case ListingFrontType.NOT_MENTIONED:
      return 'dontKnow';
    case ListingFrontType.STAPLED_STONE:
      return 'stapledStone';
    case ListingFrontType.GRINDING_STONE:
      return 'stoneMillstone';
    default:
      return 'other';
  }
};

export const formatFeesRecurrence = (
  type?: ListingFeesRecurrenceType
): string => {
  switch (type) {
    case ListingFeesRecurrenceType.MONTHLY:
      return 'month';
    case ListingFeesRecurrenceType.QUARTERLY:
      return 'quarter';
    case ListingFeesRecurrenceType.ANNUAL:
      return 'year';
    default:
      return 'month';
  }
};

export const formatFloor = (
  type: PropertyEnum,
  floor?: number | null
): string => {
  const isGroundFloor = !isDefined(floor) || floor === 0;

  if (isGroundFloor && isHouse(type)) return 'singleStorey';
  return isGroundFloor ? 'groundFloor' : '';
};

export const formatHeating = (heating: ListingHeatingMode | string): string => {
  if (
    Object.values(ListingHeatingMode).includes(heating as ListingHeatingMode)
  ) {
    return heating.toLowerCase();
  }

  return heating;
};

export const formatHeatingLocation = (
  location?: ListingHeatingLocation
): string => {
  switch (location) {
    case ListingHeatingLocation.UNDERFLOOR:
      return 'underfloor';
    case ListingHeatingLocation.WALL:
      return 'wall';
    default:
      return 'other';
  }
};

export const formatHeatingType = (
  type?: ListingHeatingType | number
): string =>
  type === ListingHeatingType.COMMUNAL || type === 1
    ? 'collectiveHeating'
    : 'individualHeating';

export const formatLivingRoomViewType = (
  livingRoomView?: ListingLivingRoomViewType
): string => {
  switch (livingRoomView) {
    case ListingLivingRoomViewType.STREET:
      return 'street';
    case ListingLivingRoomViewType.COURTYARD:
      return 'courtyard';
    case ListingLivingRoomViewType.SEA:
      return 'sea';
    case ListingLivingRoomViewType.PARK:
      return 'park';
    case ListingLivingRoomViewType.GARDEN:
      return 'garden';
    default:
      return 'dontKnow';
  }
};

export const formatLuminosity = (luminosityScore: number): string => {
  if (luminosityScore) return luminosityScore < 5 ? 'bright' : 'veryBright';
  return 'dontKnow';
};

export const formatParkingType = (type?: ListingParkingType): string => {
  switch (type) {
    case ListingParkingType.INSIDE:
      return 'inside';
    case ListingParkingType.OUTSIDE:
      return 'outside';
    default:
      return 'other';
  }
};

export const formatWindowType = (windowType?: ListingWindowType): string => {
  switch (windowType) {
    case ListingWindowType.SINGLE_GLAZING:
      return 'simpleWindow';
    case ListingWindowType.DOUBLE_GLAZING:
      return 'doubleWindow';
    case ListingWindowType.TRIPLE_GLAZING:
      return 'tripleWindow';
    default:
      return 'dontKnow';
  }
};

export const formatQuality = (quality?: ListingQuality): string => {
  switch (quality) {
    case ListingQuality.NEED:
      return 'renovationNeeded';
    case ListingQuality.REFRESH:
      return 'renovationRefreshed';
    case ListingQuality.CORRECT:
      return 'renovationCorrect';
    case ListingQuality.NEW:
      return 'renovationNew';
    default:
      return 'other';
  }
};

export const formatYesOrNo = (value?: boolean): string =>
  value ? 'yes' : 'no';

export const generateInformation = (
  informations: ListingInformationProps[]
): ListingInformationProps[][] => {
  const filteredInformations = informations.filter(
    current => current.isVisible
  );
  const array: ListingInformationProps[][] = [];

  for (let i = 0; i < filteredInformations.length; i = i + 2) {
    const current = filteredInformations[i];
    const next =
      filteredInformations.length > i + 1 && filteredInformations[i + 1];

    if (next) {
      array.push([current, next]);
    } else {
      array.push([current, { label: '', text: '', isVisible: true }]);
    }
  }

  return array;
};

export const isCoproVotedBuildNotEmpty = (coproVotedBuild?: string): boolean =>
  isNotEmpty(coproVotedBuild) &&
  coproVotedBuild !== '0' &&
  coproVotedBuild !== 'Aucun';

export const isDefined = (value?: string | number | null): boolean =>
  value !== null && value !== undefined;

export const isFlat = (type: PropertyEnum): boolean =>
  type === PropertyEnum.FLAT;

export const isHouse = (type: PropertyEnum): boolean =>
  type === PropertyEnum.HOUSE;
