import { useTranslation } from 'react-i18next';

import { RangeType } from '@/components/alert/buyerAlertForm/interfaces';
import { formatPrice } from '@proprioo/hokkaido';

import { OtherCriteriasProps, RangeFilterValue } from '../search/interfaces';

export const orderByAsc = (values: string[]): string[] =>
  values.sort((firstValue: string, secondValue: string) => {
    if (firstValue > secondValue) {
      return 1;
    }
    if (firstValue < secondValue) {
      return -1;
    }
    return 0;
  });

export const joinValues = (values: string[], separator = ', '): string =>
  orderByAsc(values).join(`${separator}`);

export const getRangeValues = (range: RangeFilterValue): RangeFilterValue => {
  if (range.min || range.max) {
    return range;
  }
  return { min: 0, max: 0 };
};

export const formatRangeValues = (min: string, max: string): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  if (min && max) {
    return t('range', { min, max });
  } else if (min) {
    return t(RangeType.MIN, { min });
  } else if (max) {
    return t(RangeType.MAX, { max });
  }
  return '';
};

export const formatRangePrice = (price: RangeFilterValue = {}): string => {
  const { min, max }: RangeFilterValue = getRangeValues(price);
  return formatRangeValues(
    (min && formatPrice(min)) || '',
    (max && formatPrice(max)) || ''
  );
};

export const formatSurface = (surface: RangeFilterValue = {}): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const { min, max }: RangeFilterValue = getRangeValues(surface);
  return formatRangeValues(
    (min && t('squareFeetFilter', { value: Math.floor(min) })) || '',
    (max && t('squareFeetFilter', { value: Math.floor(max) })) || ''
  );
};

export const defaultFormat = (range: RangeFilterValue = {}) => {
  const { min, max }: RangeFilterValue = getRangeValues(range);
  return formatRangeValues(
    (min && min.toString()) || '',
    (max && max.toString()) || ''
  );
};

export const extractOtherCriterias = (
  other?: OtherCriteriasProps
): string[] => {
  const criterias =
    other &&
    Object.entries(other).reduce((acc: string[], [key, value]) => {
      if (
        value && typeof value === 'string' ? Boolean(JSON.parse(value)) : value
      ) {
        acc.push(key);
      }

      return acc;
    }, []);

  return criterias || [];
};

export const getTruthyCriteria = (other?: OtherCriteriasProps) =>
  other &&
  Object.entries(other).reduce((acc: Record<string, boolean>, [key, value]) => {
    if (
      value && typeof value === 'string' ? Boolean(JSON.parse(value)) : value
    ) {
      acc[key] = true;
    }

    return acc;
  }, {});

export const countOtherLabel = (criterias: string[] | undefined): number =>
  criterias?.length || 0;
