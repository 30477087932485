import { FC } from 'react';

import { CRITERIAS } from '../search/utils';
import { Item, Layout } from './ListingCriterias.styles';

export type ListingCriteriasProps = {
  criterias?: string[];
};

const ListingCriterias: FC<ListingCriteriasProps> = ({ criterias }) => (
  <Layout>
    {criterias?.map((criteria, key) => (
      <Item data-test={`criteria-item-${criteria}`} key={key}>
        {CRITERIAS[criteria].image}
      </Item>
    ))}
  </Layout>
);

export default ListingCriterias;
