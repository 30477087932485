import { LinkProps } from 'next/link';

import {
  LocationState,
  RangeFilterValue
} from '@/components/search/search/interfaces';
import {
  Language,
  nullOrBoolean,
  nullOrNumber,
  nullOrString,
  UserProps
} from '@proprioo/hokkaido';
import { OptionProps } from '@proprioo/salatim';

import { OtherCriteriasProps } from '../../search/search/interfaces';
import {
  CriteriaState,
  SelectedProperties
} from './criteriaForm/CriteriaForm.types';

export type ProjectState = {
  agentOwner: nullOrNumber;
  createdFrom: CreatedFrom;
  creditStage: OptionProps | null;
  enabled: boolean;
  internalComment: nullOrString;
  isSeller: OptionProps | null;
  purchaseStage: OptionProps | null;
  source: string;
};

export enum SellerStatus {
  IS_SELLER = 'isSeller',
  IS_NOT_SELLER = 'isNotSeller'
}

export enum CriteriaTypesCheck {
  HOUSE = 'criteria.propertyTypes.house',
  FLAT = 'criteria.propertyTypes.flat'
}

export enum CreatedFrom {
  ALERT = 'alertOnline',
  TOOL = 'projectCall'
}

export type QualificationProps = {
  alertID: string;
  projectID: string;
  salesforceID?: string;
  userID: string;
  createdAt: string;
  lastModified: string;
  alert: AlertObjectResponse;
  project: ProjectPayload;
  user: UserPayload;
};

export type AreaPayload = {
  postalCode?: string;
  city?: string;
};

export type DefaultCriteriaState = {
  budget?: RangeFilterValue;
  isMap?: boolean;
  land?: RangeFilterValue;
  locationState?: LocationState[];
  nbBedrooms?: RangeFilterValue;
  other?: OtherCriteriasProps;
  propertyTypes?: SelectedProperties;
  rooms?: RangeFilterValue;
  surface?: RangeFilterValue;
  withoutRenovationWork?: boolean;
};

export type ProjectPayload = {
  agentOwner: nullOrNumber;
  createdFrom: CreatedFrom;
  enabled: boolean;
  internalComment: nullOrString;
  source: string;
  loanStage?: string;
  purchaseStage?: string;
  isSeller?: boolean;
};

export type AlertObjectPayload = {
  enabled: boolean;
  isMap: boolean;
  propertyTypes: string[];
  zones: string[];
  groundFloor?: nullOrBoolean;
  hasCellarOrAnnex?: nullOrBoolean;
  hasLift?: nullOrBoolean;
  hasParking?: nullOrBoolean;
  hasPool?: nullOrBoolean;
  hasOutdoorSpace?: nullOrBoolean;
  minBedrooms?: nullOrNumber;
  maxBedrooms?: nullOrNumber;
  minLandingArea?: nullOrNumber;
  maxLandingArea?: nullOrNumber;
  minPrice?: nullOrNumber;
  maxPrice?: nullOrNumber;
  minRooms?: nullOrNumber;
  maxRooms?: nullOrNumber;
  minSurface?: nullOrNumber;
  maxSurface?: nullOrNumber;
  withoutRenovationWork?: nullOrBoolean;
};

export type AlertObjectResponse = Omit<AlertObjectPayload, 'zones'> & {
  zones: LocationState[];
};

export type UserPayload = {
  email: string;
  firstname: string;
  language: Language;
  lastname: string;
  phoneNumber: nullOrString;
  promotionalConsent: boolean;
};

export type AlertPayload = {
  alert: AlertObjectPayload;
  project: ProjectPayload;
  user: UserPayload;
  notification: boolean;
};

export type BuyerAlertFormProps = {
  initialProperties: AlertFormState;
  labelButton?: string;
  isUserPrefilled?: boolean;
  onSubmit(properties: AlertFormState): Promise<LinkProps>;
  onChange?(): void;
};

export type CreatedAlert = {
  alertID: string;
  userID: string;
  projectID: string;
};

export type AlertFormState = {
  criteria: CriteriaState;
  project: ProjectState;
  user: UserProps;
  promotionalConsent: boolean;
  alertID?: string;
  userID?: string;
  projectID?: string;
  notification?: boolean;
};

export enum RangeType {
  MIN = 'min',
  MAX = 'max'
}
