import Image from 'next/image';
import React, { FC, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import ArrowRight from '@/assets/icons/arrow-reverse.svg';
import ArrowLeft from '@/assets/icons/arrow.svg';
import { Picture } from '@/components/listing/listing/Listing.interfaces';
import { breakpoints } from '@proprioo/salatim';

import { Counter, ImageWrapper, Layout } from './Carousel.styles';
import SlickButton from './SlickButton';

export enum Lazyload {
  ON_DEMAND = 'ondemand',
  PROGRESSIVE = 'progressive'
}

export const DEFAULT_SETTINGS_CAROUSEL: Settings = {
  autoplay: false,
  centerMode: true,
  centerPadding: '0px',
  className: 'slick-track-desktop',
  dots: true,
  dotsClass: 'slick-dots',
  infinite: false, // added to avoid https://github.com/akiran/react-slick/issues/2387 in v0.30.2
  nextArrow: (
    <SlickButton>
      <ArrowLeft />
    </SlickButton>
  ),
  prevArrow: (
    <SlickButton>
      <ArrowRight />
    </SlickButton>
  ),
  responsive: [
    {
      breakpoint: breakpoints.small,
      settings: {
        className: 'slick-track-mobile',
        adaptiveHeight: true,
        centerMode: true,
        arrows: false
      }
    }
  ],
  slidesToScroll: 1,
  slidesToShow: 1
};

type CarouselProps = {
  pictures: Picture[];
  displayCounter?: boolean;
  lazyLoad?: Lazyload;
  onImageClick?(newStartAt: number): void;
};

const Carousel: FC<CarouselProps> = ({
  displayCounter = false,
  lazyLoad = Lazyload.ON_DEMAND,
  pictures,
  onImageClick
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  return (
    <Layout data-test="carousel-slider">
      <Slider
        {...DEFAULT_SETTINGS_CAROUSEL}
        beforeChange={(_, next: number): void => setCurrentSlide(next)}
        lazyLoad={lazyLoad}
      >
        {pictures.map(({ url }, index) => (
          <ImageWrapper
            key={index}
            data-test="picture-item"
            onClick={() => onImageClick && onImageClick(index)}
          >
            <Image alt="" fill={true} loading="lazy" quality={85} src={url} />
          </ImageWrapper>
        ))}
      </Slider>
      {displayCounter && (
        <Counter>
          {currentSlide + 1}/{pictures.length}
        </Counter>
      )}
    </Layout>
  );
};

export default Carousel;
