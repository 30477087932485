import styled from 'styled-components';

import { breakpoints, colors, convertPxToRem, radius } from '@proprioo/salatim';

export const Layout = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, ${convertPxToRem(32)});
  grid-column-gap: ${convertPxToRem(4)};
  grid-row-gap: ${convertPxToRem(8)};
  margin: 0;
  padding: 0;

  @media (min-width: ${breakpoints.xsmall}px) {
    grid-template-columns: repeat(4, ${convertPxToRem(44)});
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.rounded};
  height: ${convertPxToRem(32)};
  width: ${convertPxToRem(32)};
  color: ${colors.terracota.base};
  background-color: ${colors.pink.base};

  > svg {
    height: ${convertPxToRem(18)};
    width: ${convertPxToRem(18)};
  }

  @media (min-width: ${breakpoints.xsmall}px) {
    height: ${convertPxToRem(44)};
    width: ${convertPxToRem(44)};

    > svg {
      height: ${convertPxToRem(24)};
      width: ${convertPxToRem(24)};
    }
  }
`;
