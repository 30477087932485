import { transparentize } from 'polished';
import styled from 'styled-components';

import {
  colors,
  convertPxToRem,
  fonts,
  radius,
  shadows
} from '@proprioo/salatim';

export const IMAGE_RATIO = 2 / 3;

export const LayoutArrow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${convertPxToRem(40)};
  width: ${convertPxToRem(40)};
  border-radius: ${radius.rounded};
  background: white;
  box-shadow: ${shadows.level2};
  visibility: hidden;
  transform: scale(0.8);
  transition: 0.2s transform;

  svg {
    width: 100%;
    height: 100%;

    &,
    path {
      fill: ${colors.dark.base};
    }
  }

  &:hover {
    transform: scale(1);
  }

  &:active {
    svg,
    path {
      fill: ${colors.dark.base};
    }
  }
`;

export const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .slick-dots {
    z-index: 100;

    li {
      margin: 0 ${convertPxToRem(4)};

      button {
        :before {
          color: transparent;
        }
      }

      &.li-active {
        button {
          :before {
            color: transparent;
          }
        }
      }
    }
  }

  .slick-track-desktop .slick-track,
  .slick-track-mobile .slick-track {
    height: 100%;
  }

  .slick-next,
  .slick-prev {
    z-index: 20;
    width: ${convertPxToRem(40)};
    height: ${convertPxToRem(40)};
  }

  .slick-next {
    right: -${convertPxToRem(20)};
  }

  .slick-prev {
    left: -${convertPxToRem(20)};
  }

  .slick-dots {
    bottom: ${convertPxToRem(24)};

    li {
      z-index: 100;
      width: ${convertPxToRem(8)};
      height: ${convertPxToRem(8)};
      border: ${convertPxToRem(1)} solid white;
      border-radius: ${radius.big};

      &.slick-active {
        background: white;
      }

      button {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
  }

  .slick-list {
    height: auto !important; // overload the height inline style
  }
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: ${convertPxToRem(18)};
  bottom: ${convertPxToRem(18)};
  height: ${convertPxToRem(18)};
  padding: 0 ${convertPxToRem(8)};
  color: white;
  font-size: ${fonts.smallest};
  background: ${transparentize(0.5, colors.dark.base)};
  border-radius: ${radius.biggest};
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.grey.base20};

  ::before {
    content: '';
    display: block;
    padding-top: ${(IMAGE_RATIO * 100).toFixed(1)}%;
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 100%;
  }
`;
