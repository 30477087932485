import { LocationSearchType } from '@/components/common/locationRefinement/interfaces';
import { Validation } from '@/utils/listing/helpers';
import {
  EstimationStage,
  ListingPicture,
  ListingStatus,
  MandateChargedToType,
  MandateType,
  nullOrNumber,
  nullOrString,
  PropertyEnum,
  VisitStatus
} from '@proprioo/hokkaido';

export type AgentViewing = {
  customPhone: string;
  diagnosisAffiliatedLink: nullOrString;
  firstname: string;
  id: number;
  lastname: string;
  phone: string;
};

type Location = {
  geom: string[][];
  id: string;
  name: string;
  type: LocationSearchType;
};

export type Picture = {
  url: string;
};

export type Estimation = {
  agentId: number;
  estimationStage: EstimationStage;
};

export type MandateInfo = {
  exclusivityEndDate: string;
  mandateType: MandateType;
  chargedTo?: MandateChargedToType;
};

export type Geom = {
  geom: string[][];
};

export type ListingViewing = {
  status: VisitStatus;
  uuid: string;
  visitor: string;
};

export enum ListingFrontType {
  UNDEFINED,
  CUT_STONE,
  BRICK,
  CONCRETE,
  COAT,
  CLASSIC,
  NOT_MENTIONED,
  STAPLED_STONE,
  GRINDING_STONE
}

export enum ListingFeesRecurrenceType {
  UNDEFINED,
  MONTHLY,
  QUARTERLY,
  ANNUAL
}

export enum ListingHeatingMode {
  CPCU = 'CPCU',
  ELECTRIC = 'Electric',
  FUEL = 'Fuel',
  GAS = 'Gaz',
  GEOTHERMAL = 'Geothermal',
  HEAT_PUMP = 'HeatPump',
  PELLETS = 'Granulés',
  SOLAR = 'Solaire',
  UNDEFINED = '0',
  WOOD = 'Wood'
}

export enum ListingHeatingLocation {
  UNDEFINED = '0',
  UNDERFLOOR = 'Au sol',
  WALL = 'Mural'
}

export enum ListingHeatingType {
  UNDEFINED,
  COMMUNAL,
  INDIVIDUAL
}

export enum ListingLivingRoomViewType {
  COURTYARD = 'Cour',
  GARDEN = 'Jardin',
  PARK = 'Parc',
  SEA = 'Mer',
  STREET = 'Rue',
  UNDEFINED = '0'
}

export enum ListingParkingType {
  INSIDE = 'Intérieur',
  OUTSIDE = 'Exterieur',
  UNDEFINED = '0'
}

export enum ListingQuality {
  NEED,
  REFRESH,
  CORRECT,
  NEW
}

export enum ListingWindowType {
  UNDEFINED,
  SINGLE_GLAZING,
  DOUBLE_GLAZING,
  TRIPLE_GLAZING
}

export type ListingAttributes = {
  agentId: number;
  annex: boolean;
  balconySurface: number;
  box: boolean;
  cellar: boolean;
  cellarSurface: number;
  city: string;
  constructionDate: number;
  coproCharges: number;
  coproChargesType: ListingFeesRecurrenceType;
  coproLegalProcess: boolean;
  coproNb: number;
  coproNbUnits: number;
  coproPaidBuild: boolean;
  coproRecentBuild: string;
  coproVotedBuild: string;
  createdAt: string;
  description: string;
  digicode: boolean;
  dpe?: number;
  electricCharge: number;
  electricType: ListingFeesRecurrenceType;
  elevator: boolean;
  emptyKitchen: boolean;
  energyDiagnosticDate: nullOrString;
  equippedKitchen: boolean;
  estimation: Estimation;
  facade: ListingFrontType;
  facadeDate: number;
  finalSaleDate: nullOrString;
  floor: number;
  furnished: boolean;
  gardenSurface: number;
  ges?: number;
  groundFloor: boolean;
  guardian: boolean;
  hasCellarOrAnnex: boolean;
  hasLift: boolean;
  hasOutdoorSpace: boolean;
  hasParking: boolean;
  hasPool: boolean;
  heating: ListingHeatingMode;
  heatingPosition: ListingHeatingLocation;
  heatingType: ListingHeatingType;
  id: number;
  independantKitchen: boolean;
  independantKitchenUS: boolean;
  interphone: boolean;
  irisPolygon: Geom | null;
  isOccupied: boolean;
  kitchenUS: boolean;
  latitude: number;
  livingRoomOrientedEast: boolean;
  livingRoomOrientedNorth: boolean;
  livingRoomOrientedNorthEast: boolean;
  livingRoomOrientedNorthWest: boolean;
  livingRoomOrientedSouth: boolean;
  livingRoomOrientedSouthEast: boolean;
  livingRoomOrientedSouthWest: boolean;
  livingRoomOrientedWest: boolean;
  livingSurface: string;
  locationFromMunicipality: Location;
  locationFromSubmunicipality: Location;
  longitude: number;
  luminosityScore: string;
  nbBedrooms: number;
  nbBathrooms: number;
  nbFloors: number;
  nbFloorsListing: nullOrNumber;
  nbRooms: number;
  nbSanibroyeur: number;
  nbSeparatedToilet: number;
  nbShowerRooms: number;
  nbToilets: number;
  mandateInfo: MandateInfo | null;
  municipalityPolygon: Geom | null;
  openedKitchen: boolean;
  orientedEast: boolean;
  orientedNorth: boolean;
  orientedNorthEast: boolean;
  orientedNorthWest: boolean;
  orientedSouth: boolean;
  orientedSouthEast: boolean;
  orientedSouthWest: boolean;
  orientedWest: boolean;
  parking: boolean;
  parkingCamera: boolean;
  parkingPlaces: number;
  parkingRamp: boolean;
  parkingType: ListingParkingType;
  pictures: ListingPicture[];
  pool: boolean;
  postalCode: string;
  price: number;
  processStage: Validation | null;
  propertyTax: number;
  publicationDate: string;
  quality?: ListingQuality;
  recentBuild: string;
  status: ListingStatus;
  street: string;
  streetNumber?: string;
  surface: number;
  title: string;
  todoBuild: string;
  type: PropertyEnum;
  url: string;
  uuid: string;
  viewingAgent: AgentViewing;
  viewings: ListingViewing[];
  virtualVisit: string;
  windowInsulation: boolean;
  windowType: ListingWindowType;
  windowView: ListingLivingRoomViewType;
  withoutRenovationWork: boolean;
};
