import { FC, PropsWithChildren } from 'react';
import { CustomArrowProps } from 'react-slick';

import { LayoutArrow } from './Carousel.styles';

const SlickButton: FC<PropsWithChildren<CustomArrowProps>> = ({
  currentSlide,
  slideCount,
  children,
  ...props
}) => (
  <span {...props}>
    <LayoutArrow>{children}</LayoutArrow>
  </span>
);

export default SlickButton;
